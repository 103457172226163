.container {
    margin: 0px calc(-1 * var(--paddingSide));
    position: relative;
    overflow: hidden;
    background-color: var(--gray);
    height: 88vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content_container {
    margin-top: -100px;
    padding: 0px var(--paddingSide); 
    position: relative;
    z-index: 1;
}

.heading {
    font-size: 3em;
    color: #262E68;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
}

.animation_container {
    display: flex;
    width: 55%;
    position: relative;
    z-index: 1;
}

.donut {
    width: 15%;
}

.section_cont {
    width: 15%;
    padding-top: 15%;
    position: relative;
    animation-iteration-count: infinite;
    animation-duration: 10s;
	transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
}

.icon {
    max-width: 35px;
    max-height: 40px;
    margin-right: 5px;
}

.scroll_cont {
    position: absolute;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--accentColor);
    padding: 8px;
    margin-left: 5px;
    color: white;
}

.chev {
    font-size: 0.9em;
    animation-iteration-count: infinite;
    animation-duration: 3.25s;
    animation-name: chev;
    overflow: hidden;
}

.anim_content {
    position: absolute;
    display: flex;
    top: 0px;
    left: 0px;
    width: 80%;
    height: 100%;
    left: 10%;
    align-items: center;
    justify-content: center;
    animation-iteration-count: infinite;
    animation-duration: 10s;
}

.anim_title {
    font-size: 0.8em;
}

.circle {
    border-radius: 50%;
}

.square {
    border-radius: 15px;
}


.anim0 {
    animation-name: anim1;
}

.anim1 {
    animation-name: anim2;
}

.anim2 {
    animation-name: anim3;
}

.anim3 {
    animation-name: anim4;
}

.anim4 {
    animation-name: anim5;
}

.animT0 {
    animation-name: animT1;
}

.animT1 {
    animation-name: animT2;
}

.animT2 {
    animation-name: animT3;
}

.animT3 {
    animation-name: animT4;
}

.animT4 {
    animation-name: animT5;
}


@keyframes anim1 {
    0% {
      width: 25%;
      border-radius: 150px;
    }
    10% {
      width: 25%;
      border-radius: 150px;
    }
    20% {
      width: 15%;
      border-radius: 15px;
    }
    90% {
      width: 15%;
      border-radius: 15px;
    } 
    100% {
      width: 25%;
      border-radius: 150px;
    }  
  }

  @keyframes anim2 {
    10% {
      width: 15%;
      border-radius: 50%;
    }
    20% {
      width: 25%;
      border-radius: 150px;
    }
    30% {
      width: 25%;
      border-radius: 150px;
    } 
    40% {
      width: 15%;
      border-radius: 50%;
    }  
  }

  @keyframes anim3 {
    30% {
      width: 15%;
      border-radius: 15px;
    }
    40% {
      width: 25%;
      border-radius: 150px;
    }
    50% {
      width: 25%;
      border-radius: 150px;
    } 
    60% {
      width: 15%;
      border-radius: 15px;
    } 
  }

  @keyframes anim4 {
    50% {
      width: 15%;
      border-radius: 50%;
    }
    60% {
      width: 25%;
      border-radius: 150px;
    }
    70% {
      width: 25%;
      border-radius: 150px;
    } 
    80% {
      width: 15%;
      border-radius: 50%;
    }  
  }
  @keyframes anim5 {
    70% {
      width: 15%;
      border-radius: 15px;
    }
    80% {
      width: 25%;
      border-radius: 150px;
    }
    90% {
      width: 25%;
      border-radius: 150px;
    } 
    100% {
      width: 15%;
      border-radius: 15px;
    }  
  }


  @keyframes animT1 {
    0% {
        opacity: 1;
    }
    8% {
        opacity: 1;
    }
    20% {
        opacity: 0;
    }
    90% {
        opacity: 0;
    } 
    100% {
        opacity: 1;
    }  
  }

  @keyframes animT2 {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    28% {
        opacity: 1;
    } 
    40% {
        opacity: 0;
    }  
    100% {
        opacity: 0;
    }  
  }

  @keyframes animT3 {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    48% {
        opacity: 1;
    } 
    60% {
        opacity: 0;
    } 
    100% {
        opacity: 0;
    }
  }

  @keyframes animT4 {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    68% {
        opacity: 1;
    } 
    80% {
        opacity: 0;
    }  
    100% {
        opacity: 0;
    }
  }
  @keyframes animT5 {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    88% {
        opacity: 1;
    } 
    100% {
        opacity: 0;
    }  
  }

  @keyframes chev {
    0% {
        transform: translateY(-40px);
        opacity: 0;
    }
    25% {
        transform: translateY(0px);
        opacity: 1;
    }
    50% {
        transform: translateY(0px);
        opacity: 1;
    }
    75% {
        transform: translateY(0px);
        opacity: 1;
    }
    100% {
        transform: translateY(40px);
        opacity: 0;
    }  
  }


  .decor_img {
    position: absolute;
    width: 250px;
    height: 45vh;
    z-index: 0;
    left: 225px;
    top: 125px;
    border-radius: 10px;
    filter: grayscale(100);
    overflow: hidden;
  }

  .decor_img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.15;
  }

@media screen and (min-width: 1750px) {
    .heading {
        font-size: 4em;
    }
    .icon {
        max-width: 45px;
        max-height: 50px;
    }
}

@media screen and (max-width: 1175px) {
    .animation_container {
        width: 75%;
    }
}

@media screen and (max-width: 750px) {
    .animation_container {
        width: 85%;
    }
}

@media screen and (max-width: 680px) {
    .anim_title {
        display: none;
    }
    .icon {
        max-width: 25px;
        max-height: 30px;
    }
    .heading {
        font-size: 2.4em;
    }
}




