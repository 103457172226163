.container {
    margin: 0px calc(-1 * var(--paddingSide));
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.add_offset {
    padding-top: 25px;
    transform: translateY(-25px);
}

.image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover !important;
    background-position: center !important;
}

.image:before {
    content: '';
    position: absolute;
    left: 0px;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right top, rgba(41, 66, 159, 0.95), rgba(46, 76, 182, 0.9), rgba(52, 85, 206, 0.75), rgba(58, 95, 230, 0.5), rgba(64, 105, 255, 0.3));

}

.content_container {
    display: flex;
    justify-content: space-between;
    padding: 30px calc(var(--paddingSide) + var(--addedPadding));
}

.title_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
}

.heading, .desc {
    color: white;
    margin: 0px;
}

.desc {
    margin-top: 5px;
}

.icon_container {
    width: 100px;
    height: 100px;
    background-color: #262e69;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.icon {
    height: 85px;
    padding: 15px;
}

.small_icon {
    height: 74px;
}

@media screen and (min-width: 1750px) {
    .heading {
        font-size: 2.25em;
    }

    .desc {
        font-size: 1.25em;
    }
}

@media screen and (max-width: 850px) {
    .icon_container {
        width: 80px;
        height: 80px;
    }
    .icon {
        height: 70px;
        padding: 15px;
    }
    
    .small_icon {
        height: 60px;
    }
}

@media screen and (max-width: 650px) {
    .icon_container {
        width: 70px;
        height: 70px;
    }
    .icon {
        height: 60px;
        padding: 15px;
    }
    
    .small_icon {
        height: 50px;
    }
    .desc {
        font-size: 0.9em;
    }
}

@media screen and (max-width: 500px) {
    .desc {
        font-size: 0.8em;
    }
    .icon_container {
        position: absolute;
        top: 0px;
        right: 0px;
        border-radius: 0px;
        border-bottom-left-radius: 10px;
        width: fit-content;
        height: fit-content;
    }

    .icon {
        height: 50px;
        padding: 15px;
    }
    
    .small_icon {
        height: 45px;
    }

    .add_offset > .content_container > .icon_container {
        top: 25px;
    }
    .title_container {
        width: 100%;
    }
}
