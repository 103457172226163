.container {
    display: flex;
    align-items: center;
    margin-right: 50px;
}

.title {
    text-transform: uppercase;
    font-size: 0.9em;
    margin-right: 10px;
    font-weight: 500;
}

.dropDown_container {
    min-width: 250px;
}

@media screen and (min-width: 1750px) {
    .title {
        font-size: 1.1em;
    }

    .dropDown_container {
        min-width: 280px;
    }
}

@media screen and (max-width: 1050px) {
    .container {
        flex-direction: column;
        align-items: flex-start;
    }

    .title {
        font-size: 0.75em;
    }
}

@media screen and (max-width: 750px) {
    .dropDown_container {
        min-width: 225px;
    }
}

@media screen and (max-width: 650px) {
    .dropDown_container {
        min-width: 200px;
    }
}

@media screen and (max-width: 500px) {
    .container {
        margin-right: 25px;
    }
}

@media screen and (max-width: 430px) {
    .dropDown_container {
        min-width: 150px;
    }    
}





