.inputs_container {
    margin-bottom: 10px;
}

.margined_inputs {
    margin-top: 20px;
}

.flexed_inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.justified_inputs {
    justify-content: space-between;
}

.section_icons {
    background-color: var(--accentColor);
    padding: 10px;
    border-radius: 50px;
}

.secIcon {
    height: 20px;
    margin: 0px 5px;
    cursor: pointer;
    transition: 0.3s ease;
}

.secIcon:hover {
    transform: scale(1.2);
}

.gray_container {
    margin: 0px calc(-1 * var(--paddingSide));
    padding: 15px calc(var(--paddingSide) + var(--addedPadding));
    background-color: var(--gray);
    position: relative;
    z-index: 2;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.fab_container {
    position: absolute;
    top: 95vh;
    right: 40px;
}

.fixed_button {
    position: sticky;
    top: 0px;
    background-color: var(--accentColor);
}

@media screen and (min-width: 1750px) {
    .secIcon {
        height: 25px;
        margin: 0px 10px;
    }
}

@media screen and (max-width: 1050px) {
    .dropdowns {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 750px) {
    .secIcon {
        height: 15px;
    }
}

@media screen and (max-width: 500px) {
    .dropdowns {
        flex-direction: column;
        align-items: flex-start;
    }
    .dropdown_input_cont {
        display: flex;
    }
    .justified_inputs {
        align-items: flex-start;
    }
    .legend {
        flex-direction: column;
    }
    .flexed_keys {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (max-width: 430px) {
    .inputs_container {
        justify-content: space-between;
    }
    .section_icons {
        position: absolute;
        bottom: 0px;
        border-radius: 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        right: var(--paddingSide);
    }    
}




  
  