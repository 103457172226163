.container {
    padding: 20px calc(var(--paddingSide) + var(--addedPadding));
    margin: 0px calc(-1 * var(--paddingSide));
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: relative;
    z-index: 2;
}

.gray {
    background-color: var(--gray);
}

.title {
    margin-top: 0.5em;
    font-size: 2.5em;
    text-transform: uppercase;
    color: var(--headingText);
}

.whiteTxt {
    color: white;
}

.blueBG {
    background-color: var(--primaryCountry);
}

.image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-size: cover !important;
    background-position: center !important;
    overflow: hidden;
}

.image:before {
    content: '';
    position: absolute;
    left: 0px;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right top, rgba(41, 66, 159, 0.95), rgba(46, 76, 182, 0.9), rgba(52, 85, 206, 0.75), rgba(58, 95, 230, 0.5), rgba(64, 105, 255, 0.3));
}

.removeRadius {
    border-radius: 0px;
}

.sticky {
    position: sticky;
    top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 3;
}

.desc {
    font-size: 0.9em;
    margin: -0.6em 0px;
}

@media screen and (min-width: 1750px) {
    .title {
        font-size: 3.25em;
    }
    .desc {
        font-size: 1.1em;
    }
}

@media screen and (max-width: 430px) {
    .sticky {
        padding-bottom: 30px;
    }    
}