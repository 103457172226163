.countryInfo {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  min-height: 22px;
  width: 100%;
}

.countryInfoCenter {
  justify-content: center;
  text-align: center;
}

.countryLabel {
  font-size: 0.825em;
  text-transform: uppercase;
  color: #5d5d5d;
  max-width: 65%;
}

.countryProp {
  width: 35%;
  font-size: 18px;
  font-weight: 500;
  padding-left: 5px;
}

.boxPairAndCaptionContain {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
}

.boxPairAndCaptionContainWithIcon {
  width: calc(33.3% - 6px);
  margin: 3px;
  border: 1px solid #ddd;
  padding-top: 10px;
  border-radius: 10px;
  
}

.caption {
  font-size: 16px;
  font-weight: 500;
  color: #262E68;
  height: 95px;
}

.captionCentered {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1750px) {
  .caption {
    font-size: 1.25em;
    height: 130px;
  }
  .countryLabel {
    margin-top: 10px;
    font-size: 1em;
  }
  
  .countryProp {
    font-size: 1.4em;
  }
}


@media screen and (max-width: 1000px) {
  .caption {
    font-size: 0.88em;
  }
  .countryLabel {
    font-size: 0.75em;
  }
}

@media screen and (max-width: 850px) {
  .boxPairAndCaptionContain {
    width: 48%;
  }
}

@media screen and (max-width: 750px) {
  .countryInfo {
    flex-direction: column;
    align-items: flex-start;
  }
  .countryLabel, .countryProp {
    width: 100%;
    max-width: unset;
    padding: unset;
  }
  .countryLabel {
    font-size: 0.7em;
  }
  .countryProp {
    margin-top: 5px;
  }
}

@media screen and (max-width: 450px) {
  .boxPairAndCaptionContain {
    width: 92%;
    margin-left: 4%;
  }
}

