@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&family=Noto+Sans+SC&family=Russo+One&family=Tiro+Devanagari+Hindi&display=swap');

.container {
    display: flex;
    flex-direction: column-reverse;
    border: 0.1em solid var(--gray);
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 15px;
    overflow: hidden;
}

.comment_container {
    width: 95%;
}

.country_container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    padding: 5px 0px;
    border-bottom: 2px solid var(--accentColor);
    text-transform: uppercase;
}

.country {
    font-weight: 700;
    margin: 0px;
    font-size: 0.8em;
}

.original {
    font-size: 0.9em;
    margin: 10px 0px;
    font-weight: 600;
}

.English {
    font-weight: 400;
}

.trans > p {
    font-size: 0.9em;
    margin: 0px;
}

.trans > p > span {
    font-weight: 700;
}

.Ukrainian, .Dutch, .French {
    font-family: 'Russo One', sans-serif;
    font-weight: 400;
}

.Chinese_Taiwan, .Japense {
    font-family: 'Noto Sans SC', sans-serif;
}

.Arabic {
    font-family: 'Noto Sans Arabic', sans-serif;
}

.Hindi {
    font-family: 'Tiro Devanagari Hindi', serif;
}