@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --paddingSide: 100px;
  --addedPadding: 75px;
  --primaryCountry: #0346AD;
  --secondaryCountry: #A1ACFD;
  --accentColor: #4069FF;
  --gray: #ebebeb;
  --headingText: #262e68;
}

.App, Body {
  font-family: 'Inter', sans-serif;
}

*, *:before, *:after {
  box-sizing: border-box;
}

.route_animator {
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: var(--accentColor);
  transform: translateY(120vh);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.dashSection_desc {
  margin: 35px var(--addedPadding);
  position: relative;
  font-weight: 600;
  background-color: var(--accentColor);
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
}


.paddedCaption {
  margin: 35px var(--addedPadding);
}

.overwrite_invert > .MuiFormControl-root > .MuiInputBase-root, .overwrite_invert > .MuiFormControl-root > .MuiInputBase-root > .MuiAutocomplete-endAdornment > .MuiButtonBase-root {
  color: #fff !important; 
}

.overwrite_invert > .MuiFormControl-root > .MuiInputBase-root::before {
  border-bottom: 1px #eee solid !important; 
}

.overwrite_invert > .MuiFormControl-root > .MuiInputBase-root::after {
  border-bottom: 1px #e0e0e0 solid !important; 
}

.MuiAccordion-root {
  box-shadow: none !important;
}

.iconContain > .iconContain {
  margin-right: 5px;
}

@media screen and (min-width: 1750px) {
  :root {
    --paddingSide: 125px;
    --addedPadding: 100px;
  }
  .dashSection_desc {
    font-size: 1.25em;
  }
  .iconContain {
    font-size: 1.15em;
  }
  .iconContain > .iconContain {
    margin-right: 10px;
  }
}

@media screen and (max-width: 1150px) {
  :root {
    --addedPadding: 0px;
  }
}

@media screen and (max-width: 1000px) {
  :root {
    --paddingSide: 70px;
  }
}

@media screen and (max-width: 850px) {
  :root {
    --paddingSide: 45px;
  }
}

@media screen and (max-width: 750px) {
  :root {
    --paddingSide: 30px;
  }
}

@media screen and (max-width: 550px) {
  :root {
    --paddingSide: 20px;
  }
}

@media screen and (max-width: 500px) {
  .dashSection_desc {
    font-size: 0.9em;
  }
}


