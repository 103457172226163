.flexed {
    display: flex;
    align-items: flex-end;
    margin: 20px 0px;
}

.percentCont {
    display: flex;
    align-items: flex-end;
    width: fit-content;
}

.percentCont > p {
    font-size: 3em;
    font-weight: 600;
    margin: 0px;
}

.percent {
    font-size: 2em !important;
    margin-bottom: 5px !important;
}

.desc_contain {
    margin-left: 10px;
}

.desc_contain > p {
    margin-bottom: 10px;
}

.proportion {
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.proportion > p {
    font-size: 3em;
    font-weight: 600;
    margin: 0px;
}

.sup {
    font-weight: 700 !important;
    font-size: 0.9em !important;
    margin-bottom: 5px !important;
}

@media screen and (min-width: 1750px) {
    .proportion > p {
        font-size: 3.5em;
    }
    .sup {
        font-size: 1.05em !important;
    }
    .percentCont > p {
        font-size: 3.5em;
    }
    
    .percent {
        font-size: 2.25em !important;
    }
    .desc_contain {
        font-size: 1.2em;
    }
}

@media screen and (max-width: 780px) {
    .percentCont > p {
        font-size: 2.75em;
        font-weight: 600;
        margin: 0px;
    }
    
    .percent {
        font-size: 1.5em !important;
        margin-bottom: 5px !important;
    }

    .proportion > p {
        font-size: 2.75em;
        font-weight: 600;
        margin: 0px;
    }
    
    .sup {
        font-weight: 700 !important;
        font-size: 0.9em !important;
        margin-bottom: 5px !important;
    }
}