.container {
    display: flex;
    margin: 50px 0px;
    margin: 0px calc(-1 * var(--paddingSide));
    display: flex;
    margin-bottom: 75px;
    border-radius: 20px;
    overflow: hidden;
}

.dataDash, .report_dwn_cont {
    width: 50%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--paddingSide);
}

.report_dwn_cont {
    background-color: var(--gray);
}

.image_cont {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
}

.report_title {
    width: 85%;
    margin-bottom: 20px;
}

.report_dwn_cont > h3 {
    font-size: 1.4em;
}

.dataDash::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right bottom, #2c3266, #2d3778, #2c3b8a, #2b409c, #2845af);
    opacity: 0.75;
    z-index: 2;
}

.button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s ease;
    text-decoration: none;
    color: black;
    border-bottom: 2px solid var(--accentColor);
    width: fit-content;
    margin-right: 10px;
}

.button > p {
    margin: 5px;
    position: relative;
    z-index: 2;
}

.button_inv {
    color: #fff;
}

.button::before {
    content: "";
    background-color: var(--accentColor);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    transform: scaleY(0);
    transform-origin: bottom center;
	transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);
}

.button:hover {
    color: #fff;
    border-bottom: 2px solid var(--gray);
}

.button_inv:hover {
    border-bottom: 2px solid var(--primaryCountry);
}

.button:hover::before {
    transform: scaleY(1);
}

.dash_link {
    position: relative;
    z-index: 2;
}

.dash_link > h3 {
    font-size: 1.4em;
    color: #fff;
}

.flexed_buttons {
    display: flex;
    align-items: center;
}

@media screen and (min-width: 1750px) {
    .dash_link > h3, .report_dwn_cont > h3 {
        font-size: 2em;
    }
    .button > p {
        font-size: 1.25em;
    }
}

@media screen and (max-width: 775px) {
    .container {
        flex-direction: column;
    }
    .dataDash, .report_dwn_cont {
        width: 100%;
    }
}
