.container {
    background-color: var(--gray);
    margin: 0px calc(-1 * var(--paddingSide));
    margin-top: 15px;
    padding: 20px var(--paddingSide);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.left {
    width: 63%;
}

.right {
    width: 37%;
}

.logo {
    width: 175px;
}

.social_container {
    display: flex;
    justify-content: flex-end;
}

.social_icon {
    font-size: 1.2em;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.4s ease;
    position: relative;
}

.social_icon:hover {
    color: var(--accentColor);
}

.legal {
    font-size: 0.7em;
    margin-top: 20px;
    font-weight: 500;
    background-color: #fff;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 5px 20px;
    color: #000;
    position: absolute;
    bottom: 0px;
    right: var(--paddingSide);
    width: 35%;

}

.legal > p > a {
    color: var(--accentColor);
}

.copyright {
    font-size: 0.9em;
}

@media screen and (max-width: 850px) {
    .container {
        flex-direction: column;
    }
    .legal {
        position: relative;
        width: 100%;
        border-radius: 10px;
        right: unset;
    }
    .left, .right {
        position: relative;
        width: 100%;
    }
    .social_container {
        justify-content: flex-start;
    }
}

@media screen and (min-width: 1750px) {
    .social_icon {
        font-size: 1.5em;
    }

    .copyright {
        font-size: 1em;
    }

    .legal {
        font-size: 0.9em;
    }
}