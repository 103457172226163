.key_flex {
    display: flex;
    align-items: center;
}

.key_flex > p {
    margin: 0px;
}

.country_name, .respondant {
    margin: 0px;
    margin-right: 4px;
}

.respondant {
    font-size: 0.75em;
    color: #444;
}

@media screen and (min-width: 1750px) {
    .key_flex {
        font-size: 1.25em;
    }

    .respondant {
        font-size: 0.9em;
    }
}

@media screen and (max-width: 650px) {
    .key_flex {
        flex-direction: column;
        align-items: flex-start;
    }
}