.content_container {
    padding: 0px var(--paddingSide);
    opacity: 0;
    display: flex;
    flex-direction: column;
    min-height: 99vh;
}

.gray_content {
    padding-top: 100px;
    background-color: gray;
}

.flexed_content {
    display: flex;
    margin-top: 30px;
    flex-grow: 1;
}

.voices_container {
    width: 100%;
}

.filters_container {
    width: 24%;
    padding: 25px;
    background-color: var(--gray);
    border-radius: 10px;
    height: fit-content;
    max-height: 95vh;
    overflow: scroll;
    position: sticky;
    top: 2.5vh;
}

