.bar_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    width: 98%;
    overflow: hidden;
}

.bar {
    width: calc(100% - 10px);
    margin: 0px 5px;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    padding: 0.75em 0.2em;
    background-color: #EBEBEB;
    border-radius: 8px;
}

.bar > p {
    margin: 0px;
    color: #4E4E4E;
    font-weight: 500;
}

.marker {
    padding: 15px 5px;
    border-radius: 15px;
}

.info_cont, .country_desc_cont, .info_wrapper {
    display: flex;
}

.info_cont {
    width: 100%;
	transition: transform 0.9s cubic-bezier(0.45, 0, 0.55, 1);
}

.country_desc_cont {
    align-items: flex-end;
}

.data_name {
    text-transform: uppercase;
    font-size: 0.7rem;
    color: #5D5D5D;
    margin: 0px;
    margin-left: 0.2em;
    margin-bottom: 1px;
    width: 58%;
}

.data_value {
    font-size: 1.15rem;
    font-weight: 500;
    margin: 0px 0.3rem;
}

@media screen and (min-width: 1750px) {
    .data_name {
        font-size: 1rem;
    }
    
    .data_value {
        font-size: 1.4em;
    }
}

@media screen and (max-width: 1000px) {
    .data_value {
      font-size: 1rem;
      margin: 0px;
    }
    .data_name {
      font-size: 0.65rem;
    }
    .country_desc_cont {
        flex-direction: column;
        align-items: flex-start;
        margin: 0px 0.3rem;
    }
  }