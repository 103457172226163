.container {
    margin: 80px calc(-1 * var(--paddingSide));
    display: flex;
    position: relative;
}

.image_container {
    width: 28%;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0px;
    bottom: 0px;
}

.donut {
    position: relative;
    height: 87vh;
    left: -80%;
	transition: transform 0.6s cubic-bezier(0.5, 1, 0.89, 1);
}

.content {
    width: 72%;
    padding-right: var(--paddingSide);
    padding-left: 20px;
}

.wheel_content {
    margin: 350px 0px;
    background-color: rgba(255, 255, 255, 0.8);
    overflow: hidden;
}

.wheel_content:first-child {
    margin-top: 250px;
}

.wheel_content:last-child {
    margin-bottom: 250px;
}

.absDonut {
    display: none;
    position: absolute;
    right: -100px;
    bottom: -100px;
    width: 200px;
}

.mobiledonut0 {
    transform: rotate(-135deg) !important;
}

.mobiledonut1 {
    transform: rotate(155deg) !important;
}

.mobiledonut2 {
    transform: rotate(80deg) !important;
}

.mobiledonut3 {
    transform: rotate(10deg) !important;
}

.mobiledonut4 {
    transform: rotate(298deg) !important;
}

.wheel_content {
    border: 1px solid #444;
    padding: 30px 50px;
    border-radius: 15px;
    position: relative;
}

.flexed {
    display: flex;
}

.title {
    font-size: 1.8em;
    margin: 0px;
    margin-left: 10px;
}

.icon {
    height: 40px;
    filter: brightness(0);
}

.absolute_color {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #444;
    width: 35%;
    height: 20px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 10px;
}

.desc {
    width: fit-content;
    padding-bottom: 5px;
    border-bottom: 1px solid #444;
}

.text {
    margin-top: 30px;
    font-weight: 600;
    font-size: 0.9em;
    text-transform: uppercase;
}

.abs_button {
    position: absolute;
    bottom: 20px;
    right: 30px;
    border-radius: 50px;
    color: white;
    padding: 10px 15px;
    border: 0px;
    cursor: pointer;
    font-weight: 600;
}

.abs_button > p {
    margin: 0px;
}

@media screen and (min-width: 1750px) {
    .donut {
        left: -90%;
    }
    .desc {
        font-size: 1.2em;
    }
    .text {
        font-size: 1.05em;
    }
}

@media screen and (max-width: 1350px) {
    .donut {
        left: -100%;
    }
}

@media screen and (max-width: 1175px) {
    .donut {
        left: -130%;
    }
    .abs_button {
        position: relative;
        margin-top: 20px;
        right: unset;
        bottom: 0px;
    }
}

@media screen and (max-width: 1025px) {
    .donut {
        left: -170%;
    }
}

@media screen and (max-width: 880px) {
    .donut {
        left: -200%;
    }
}

@media screen and (max-width: 780px) {
    .image_container {
        display: none;
    }

    .absDonut {
        display: block;
    }
    .wheel_content {
        margin: 50px 0px;
    }
    .wheel_content:first-child {
        margin-top: 75px;
    }
    
    .wheel_content:last-child {
        margin-bottom: 75px;
    }
    .content {
        width: 100%;
        padding-left: var(--paddingSide);
    }
    .wheel_content {
        background-color: rgba(255, 255, 255, 0.8);
    }
    .donut {
        left: -350px;
    }
}

@media screen and (max-width: 450px) {
    .donut {
        left: -525px;
    }
    .wheel_content {
        padding: 75px 30px;
    }

    .abs_button {
        position: absolute;
        left: 0px;
        bottom: 0px;
        border-radius: 0px;
        border-bottom-left-radius: 15px;
        border-top-right-radius: 15px;
    }
}

@media screen and (max-width: 410px) {
    .absDonut {
        display: none;
    }
}


@media screen and (max-width: 380px) and (max-height: 700px) {
    .donut {
        left: -375px;
    }
}

@media screen and (max-width: 880px) and (min-height: 1000px) {
    .donut {
        position: relative;
        height: 67vh;
    }
}
