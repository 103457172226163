.container {
    margin: 0px calc(-1 * var(--paddingSide));
    padding: 120px calc(var(--paddingSide) + var(--addedPadding));
    background-image: linear-gradient(to right bottom, #2c3266, #2d3778, #2c3b8a, #2b409c, #2845af);
    color: white;
    position: relative;
    border-radius: 15px;
}

.title {
    font-size: 2em;
    margin-top: 0px;
}

.render > p {
    font-size: 1em;
    margin: 2em 0px;
}

.render > p:last-child {
    margin-bottom: 0px;
}

.mesh {
    position: absolute;
    filter: brightness(10.5);
    opacity: 0.2;
    bottom: 5px;
    right: 75px;
}

@media screen and (min-width: 1750px) {
    .title {
        font-size: 3em;
        margin-top: 0px;
    }
    
    .render > p {
        font-size: 1.4em;
        margin: 2em 0px;
    }
    .mesh {
        width: 50%;
    }
}

@media screen and (max-width: 775px) {
    .mesh {
        width: 80%;
        right: 30px;
    }
}