.container {
    background: var(--gray);
    padding: 10px var(--paddingSide);
    opacity: 0;
    position: relative;
    z-index: 1;
}

.white {
    background-color: #fff;
}

.curved {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.logo {
    width: 125px;
    cursor: pointer;
}

.desk_nav_flexed, .social_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.desk_nav_flexed {
    justify-content: space-between;
}

.social_container {
    justify-content: center;
    margin-left: 25px;
}

.social_icon {
    font-size: 1.2em;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.4s ease;
    position: relative;
}

.social_icon:hover {
    color: var(--accentColor);
}

.links_container {
    display: flex;
}

.nav_item {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    text-transform: uppercase;
    margin: 0px 10px;
    cursor: pointer;
    transition: 0.3s ease;
    text-decoration: none;
    color: black;
    border-bottom: 2px solid var(--accentColor);
}

.nav_item > p {
    margin: 5px;
}

.nav_item::before {
    content: "";
    background-color: var(--accentColor);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 3px;
    transform: scaleY(0);
    transform-origin: bottom center;
	transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);
}

.nav_item:hover {
    color: #fff;
    border-bottom: 2px solid var(--gray);
}

.nav_item:hover::before {
    transform: scaleY(1);
}

.menu_icon, .mobileNav {
    display: none;
}

@media screen and (min-width: 1750px) {
    .logo {
        width: 155px;
        cursor: pointer;
    }

    .nav_item {
        font-size: 1.2em;
    }

    .social_icon {
        font-size: 1.5em;
    }
}

@media screen and (max-width: 1150px) {
    .links_container {
        display: none;
    }
    .menu_icon {
        display: block;
        font-size: 1.6em;
        cursor: pointer;
    }
    .mobileNav {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 100vw;
        background: linear-gradient(45deg, var(--accentColor), var(--primaryCountry));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        transform: translateX(-100vw);
    }

    .mobileNav_close {
        position: absolute;
        top: 20px;
        right: var(--paddingSide);
        font-size: 2em;
        cursor: pointer;
    }

    .nav_item {
        font-size: 1em;
        color: white;
        justify-content: center;
        text-align: center;
        margin: 5px 0px;
    }

    .social_container {
        margin: 0px;
        margin-top: 20px;
    }

    .social_icon {
        font-size: 1.4em;
    }

    .mobile_nav_logo {
        position: absolute;
        bottom: 20px;
        width: 150px;
    }
}